import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const DownloadLinksTab: FC = () => {
  const { i18n, t } = useTranslation();

  return (
    <div
      className="container mx-auto text-center sm:text-left sm:grid sm:grid-cols-2 md:grid-cols-3 sm:place-content-center sm:gap-10 px-4 sm:px-12 md:px-20 lg:px-28 xl:px-36"
      id="whoWeAre"
    >
      <div className="col-span-1">
        <img
          alt="whoWeAre"
          className="w-56 sm:w-72 shrink-0 m-auto md:mr-0 md:ml-auto"
          src={`/images/mobile_app_${i18n.language}.png`}
        />
      </div>
      <div className="md:col-span-2 self-center">
        <h1 className="h1-title mb-0">{t('app.downloadLinks.title')}</h1>
        <p className="text-landing-purple text-base lg:text-lg">
          {t('app.downloadLinks.description')}
        </p>
        <p className="mb-0">{t('app.downloadLinks.text1')}</p>
        <p>{t('app.downloadLinks.text2')}</p>
        <div className="flex flex-row gap-5 pt-5 justify-center sm:justify-start">
          <a
            className="w-40"
            href="https://apps.apple.com/kz/app/chess-legends/id1624712486"
            rel="noreferrer"
            target="_blank"
          >
            <img alt="Apple store" src="/images/apple-store-badge.png" />
          </a>
          <a
            className="w-40"
            href="https://play.google.com/store/apps/details?id=ai.chesslegends"
            rel="noreferrer"
            target="_blank"
          >
            <img alt="Google play" src="/images/google-play-badge.png" />
          </a>
        </div>
      </div>
    </div>
  );
};
