import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const TermSeven: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.7.title')}
      </h2>
      <p>{t('terms.7.1')}</p>
      <p>{t('terms.7.11')}</p>
      <p>{t('terms.7.2')}</p>
      <p>{t('terms.7.21')}</p>
      <p>{t('terms.7.3')}</p>
      <p>{t('terms.7.31')}</p>
      <p>{t('terms.7.4')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="terms.7.5" />
      </p>
      <p>{t('terms.7.51')}</p>
      <p>{t('terms.7.6')}</p>
    </div>
  );
};
