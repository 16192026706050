import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TermSix: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.6.title')}
      </h2>
      <p>{t('terms.6.1')}</p>
    </div>
  );
};
