import en from '@utils/i18next/en.json';
import ru from '@utils/i18next/ru.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',

    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },
  });
