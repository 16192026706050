import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyEight: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.8.title')}
      </h2>
      <p>{t('privacy.8.1')}</p>
      <p>{t('privacy.8.2')}</p>
      <div className="pl-4 lg:pl-8">
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.8.21" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.8.22" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.8.23" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.8.24" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.8.25" />
        </p>
      </div>
    </div>
  );
};
