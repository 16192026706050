import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const LocaleLink: FC<{
  children?: ReactNode;
  className?: string;
  to: string;
}> = ({ children, className, to }) => {
  const location = useLocation();

  const languageLink = location.pathname.startsWith('/ru')
    ? '/ru'
    : location.pathname.startsWith('/en')
    ? '/en'
    : '';

  return (
    <Link className={className} to={`${languageLink}${to}`}>
      {children}
    </Link>
  );
};
