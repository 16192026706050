import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const MiniHeader: FC = () => {
  const { t } = useTranslation();
  return (
    <div
      className="container mx-auto px-4 sm:px-12 md:px-20 lg:px-28 xl:px-36 flex flex-col flex-col-reverse md:flex-row"
      id="launch"
    >
      <div className="pt-8">
        <h1 className="h1-title">{t('app.whoWeAre.title')}</h1>
        <p className="text-landing-purple">{t('app.whoWeAre.description')}</p>
      </div>

      <img alt="Kaissa" className="mx-auto h-48 self-end" src="/images/kaissa.png" />
    </div>
  );
};
