import { PrivacyEight } from '@components/molecules/Privacies/PrivacyEight';
import { PrivacyFive } from '@components/molecules/Privacies/PrivacyFive';
import { PrivacyFour } from '@components/molecules/Privacies/PrivacyFour';
import { PrivacyNine } from '@components/molecules/Privacies/PrivacyNine';
import { PrivacyOne } from '@components/molecules/Privacies/PrivacyOne';
import { PrivacySeven } from '@components/molecules/Privacies/PrivacySeven';
import { PrivacySix } from '@components/molecules/Privacies/PrivacySix';
import { PrivacyTen } from '@components/molecules/Privacies/PrivacyTen';
import { PrivacyThree } from '@components/molecules/Privacies/PrivacyThree';
import { PrivacyTwo } from '@components/molecules/Privacies/PrivacyTwo';
import { PageMeta } from '@context/PageMetaContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicyPage: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('privacy.full')} />
      <div className="min-h-full p-5 sm:p-10  md:p-15 lg:p-20 xl:p-25 text-ant-gray-4">
        <h1 className="text-center text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-black uppercase text-white overflow-hidden text-ellipsis">
          {t('privacy.full')}
        </h1>

        <div className="border-landing-blue shadow-blue border p-8 md:p-16 rounded-lg flex flex-col gap-8 md:gap-16">
          <PrivacyOne />
          <PrivacyTwo />
          <PrivacyThree />
          <PrivacyFour />
          <PrivacyFive />
          <PrivacySix />
          <PrivacySeven />
          <PrivacyEight />
          <PrivacyNine />
          <PrivacyTen />
        </div>
      </div>
    </>
  );
};
