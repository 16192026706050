import { LocaleLink } from '@components/atoms/LocaleLink';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyTen: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.10.title')}
      </h2>
      <p className="font-black">{t('privacy.10.1')}</p>
      <p>
        {t('privacy.10.2')}
        <LocaleLink to="/terms">
          <span>https://chesslegends.ai/terms</span>
        </LocaleLink>
      </p>
      <p>{t('privacy.10.3')}</p>
      <p className="font-black">{t('privacy.10.4')}</p>
      <p>{t('privacy.10.5')}</p>
      <p>{t('privacy.10.6')}</p>
      <p className="font-black">{t('privacy.10.7')}</p>
      <p>{t('privacy.10.8')}</p>
      <p className="font-black">{t('privacy.10.9')}</p>
      <p>{t('privacy.10.10')}</p>
    </div>
  );
};
