import { AdvantagesTab } from '@components/organisms/landingTabs/AdvantagesTab';
import { AnimationTab } from '@components/organisms/landingTabs/AnimationTab';
import { DownloadLinksTab } from '@components/organisms/landingTabs/DownloadLinksTab';
import { TeamTab } from '@components/organisms/landingTabs/TeamTab';
import { PageMeta } from '@context/PageMetaContext';
import { FC, useEffect } from 'react';

export const LandingPage: FC = () => {
  useEffect(() => {
    if (window.location.hash) {
      document
        .getElementById(window.location.hash.replace('#', ''))
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      <PageMeta title="" />
      <div className="flex flex-col gap-10 app-tabs py-6">
        <DownloadLinksTab />
        <AdvantagesTab />
        <AnimationTab />
        <TeamTab />
      </div>
    </>
  );
};
