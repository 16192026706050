import './../../../assets/css/plyr-custom.css';

import Plyr from 'plyr-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AnimationTab: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-20" id="animation">
      <h1 className="h1-title text-center">{t('app.3d.title')}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-10">
        <div>
          <div className="max-h-[300px]">
            <Plyr
              options={{
                clickToPlay: true,
                controls: ['play-large', 'play', 'progress', 'mute', 'volume', 'fullscreen'],
              }}
              preload="none"
              source={{
                poster: '/images/video_1.png',
                sources: [
                  {
                    provider: 'html5',
                    src: 'https://chesslegends.fra1.digitaloceanspaces.com/videos/3e146fed-1b46-4a81-a086-25db1fde77ef/02.mp4',
                  },
                ],
                type: 'video',
              }}
              title="Мультфильм 1"
            />
          </div>
          <h1 className="h1-title text-center mt-4">{t('app.3d.reasons.title')}</h1>
          <p>{t('app.3d.reasons.1')}</p>
        </div>
        <div>
          <div className="max-h-[300px]">
            <Plyr
              options={{
                clickToPlay: true,
                controls: ['play-large', 'play', 'progress', 'mute', 'volume', 'fullscreen'],
              }}
              preload="none"
              source={{
                poster: '/images/video_2.png',
                sources: [
                  {
                    provider: 'html5',
                    src: 'https://chesslegends.fra1.digitaloceanspaces.com/videos/22d87b9c-2f80-4550-92c2-b19e093daeff/01_ENG%20(1).mp4',
                  },
                ],
                type: 'video',
              }}
              title="Мультфильм 2"
            />
          </div>
          <h1 className="h1-title text-center mt-4">{t('app.3d.chessPlanet.title')}</h1>
          <p>{t('app.3d.chessPlanet.1')}</p>
        </div>
      </div>
    </div>
  );
};
