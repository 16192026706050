import { Footer } from '@components/organisms/Footer';
import { Header } from '@components/organisms/Header';
import { MiniHeader } from '@components/organisms/MiniHeader';
import { Layout } from 'antd';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

type LandingLayoutType = {
  menu?: boolean;
  miniHeader?: boolean;
};

export const AppLayout: FC<LandingLayoutType> = ({ menu, miniHeader }) => {
  return (
    <Layout className="flex flex-col h-screen justify-between overflow-hidden bg-landing-bg ">
      <Layout.Content className="overflow-scroll scroll-smooth">
        {miniHeader ? (
          <div className="grid header-bg gap-5">
            <Header menu={menu} />
            <MiniHeader />
          </div>
        ) : (
          <Header />
        )}
        <Outlet />
        <Footer />
      </Layout.Content>
    </Layout>
  );
};
