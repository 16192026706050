import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const TermFour: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.4.title')}
      </h2>
      <p>{t('terms.4.1')}</p>
      <p>{t('terms.4.11')}</p>
      <p>{t('terms.4.12')}</p>
      <p>{t('terms.4.13')}</p>
      <p>{t('terms.4.14')}</p>
      <p>{t('terms.4.15')}</p>
      <p>{t('terms.4.16')}</p>
      <p>{t('terms.4.17')}</p>
      <p>{t('terms.4.18')}</p>
      <p>{t('terms.4.19')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="terms.4.2" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="terms.4.3" />
      </p>
      <p>{t('terms.4.4')}</p>
      <p>{t('terms.4.41')}</p>
      <p>{t('terms.4.5')}</p>
      <p>{t('terms.4.6')}</p>
    </div>
  );
};
