import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TermNine: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.9.title')}
      </h2>
      <p>{t('terms.9.1')}</p>
      <p>{t('terms.9.11')}</p>
      <p>{t('terms.9.12')}</p>
      <p>{t('terms.9.13')}</p>
      <p>{t('terms.9.2')}</p>
    </div>
  );
};
