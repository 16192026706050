import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageRoute: FC<{
  children: JSX.Element;
  language?: 'ru' | 'en';
}> = ({ children, language }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (language && i18n.language != language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return children;
};

export default LanguageRoute;
