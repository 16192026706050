import { FC, ReactNode } from 'react';

type TeamMemberType = {
  description: ReactNode;
  imageUrl: string;
  name: string;
  title: string;
  type?: 'base' | 'blue' | 'white';
};

export const TeamMember: FC<TeamMemberType> = ({
  description,
  imageUrl,
  name,
  title,
  type = 'blue',
}) => {
  return (
    <div className="flex flex-row gap-4 lg:gap-8">
      <img
        alt={name}
        className="h-32 w-32 rounded-lg team-member-img shrink-0"
        src={`/images/team/${type}/${imageUrl}`}
      />
      <div className="text-ellipsis overflow-hidden">
        <h2 className="text-white text-2xl font-bold mb-0">{name}</h2>
        <h2 className="text-white text-2xl font-medium ">{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};
