import '@utils/axiosConfig';
import '@utils/i18next/i18nextConfig';
import './index.css';
import 'antd/dist/antd.min.css';

import { PageMetaContextProvider } from '@context/PageMetaContext';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <PageMetaContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PageMetaContextProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </StrictMode>,
  );
}

reportWebVitals();
