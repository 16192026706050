import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyFour: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.4.title')}
      </h2>
      <p className="font-black">{t('privacy.4.1')}</p>
      <p>{t('privacy.4.2')}</p>
      <div className="pl-4 lg:pl-8">
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.21" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.22" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.23" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.24" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.25" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.26" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.27" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.28" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.29" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.210" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.211" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.212" />
        </p>
      </div>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.3" />
      </p>
      <p className="font-black">{t('privacy.4.4')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.5" />
      </p>
      <p>{t('privacy.4.6')}</p>
      <p>{t('privacy.4.7')}</p>
      <p>{t('privacy.4.8')}</p>
      <p className="font-black">{t('privacy.4.9')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.10" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.4.11" />
      </p>
    </div>
  );
};
