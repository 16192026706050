import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TermThree: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.3.title')}
      </h2>
      <p>{t('terms.3.1')}</p>
      <p>{t('terms.3.2')}</p>
      <p>{t('terms.3.3')}</p>
      <p>{t('terms.3.4')}</p>
    </div>
  );
};
