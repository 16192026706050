import { LocaleLink } from '@components/atoms/LocaleLink';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row px-8 md:px-16 py-1 md:py-2 shadow-blue items-center">
      <LocaleLink className="max-w-[150px]" to="/">
        <img
          alt="logo"
          className="object-contain w-auto max-h-full min-w-[100px]"
          src="/images/logo.png"
        />
      </LocaleLink>

      <div className="flex flex-col md:flex-row items-center text-center md:text-left w-full justify-end gap-4 md:gap-12 text-sm font-medium">
        <div className="flex flex-col">
          <LocaleLink className="text-white" to="/privacy">
            {t('privacy.full')}
          </LocaleLink>
          <Link className="text-white" to="/terms">
            {t('terms.full')}
          </Link>
        </div>
        <div>
          <h1 className="mb-0 text-gray-400 font-semibold">{t('app.contact')}</h1>
          <a className="text-white text-sm" href="mailto:support@chesslegends.ai">
            support@chesslegends.ai
          </a>
        </div>
      </div>
    </div>
  );
};
