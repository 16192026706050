import { TermEight } from '@components/molecules/Terms/TermEight';
import { TermEleven } from '@components/molecules/Terms/TermEleven';
import { TermFive } from '@components/molecules/Terms/TermFive';
import { TermFour } from '@components/molecules/Terms/TermFour';
import { TermNine } from '@components/molecules/Terms/TermNine';
import { TermOne } from '@components/molecules/Terms/TermOne';
import { TermSeven } from '@components/molecules/Terms/TermSeven';
import { TermSix } from '@components/molecules/Terms/TermSix';
import { TermTen } from '@components/molecules/Terms/TermTen';
import { TermThree } from '@components/molecules/Terms/TermThree';
import { TermTwo } from '@components/molecules/Terms/TermTwo';
import { PageMeta } from '@context/PageMetaContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TermsAndConditionsPage: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMeta title={t('terms.full')} />
      <div className="min-h-full p-5 sm:p-10  md:p-15 lg:p-20 xl:p-25 text-ant-gray-4">
        <h1 className="text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black uppercase text-white overflow-hidden text-ellipsis">
          {t('terms.terms')} &amp; {t('terms.conditions')}
        </h1>
        <div className="border-landing-blue shadow-blue border p-8 md:p-16 rounded-lg flex flex-col gap-8 md:gap-16">
          <TermOne />
          <TermTwo />
          <TermThree />
          <TermFour />
          <TermFive />
          <TermSix />
          <TermSeven />
          <TermEight />
          <TermNine />
          <TermTen />
          <TermEleven />
        </div>
      </div>
    </>
  );
};
