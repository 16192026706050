import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyThree: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.3.title')}
      </h2>
      <p className="font-black">{t('privacy.3.1')}</p>
      <p>{t('privacy.3.2')}</p>
      <div className="pl-4 lg:pl-8">
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.21" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.22" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.23" />
        </p>
      </div>
      <p className="font-black">{t('privacy.3.3')}</p>
      <p>{t('privacy.3.4')}</p>
      <div className="pl-4 lg:pl-8">
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.41" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.42" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.43" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.44" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.45" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.46" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.47" />
        </p>
      </div>
      <p>{t('privacy.3.5')}</p>
      <p>{t('privacy.3.6')}</p>
      <p className="font-black">{t('privacy.3.7')}</p>
      <p>{t('privacy.3.8')}</p>
      <p className="font-black">{t('privacy.3.9')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.3.10" />
      </p>
    </div>
  );
};
