import { TeamMember } from '@components/molecules/TeamMember';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TeamTab: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container px-4 sm:px-8 lg:px-16 mx-auto pb-16" id="team">
      <h1 className="h1-title text-center mb-10">{t('app.team.title')}</h1>
      <div className="grid md:grid-cols-2 gap-5 lg:gap-10">
        <TeamMember
          description={<span className="font-bold mb-0">{t('app.team.vladimir.description')}</span>}
          imageUrl="vladimir.png"
          name={t('app.team.vladimir.name')}
          title={t('app.team.vladimir.title')}
        />
        <TeamMember
          description={t('app.team.hou.description')}
          imageUrl="hou.png"
          name={t('app.team.hou.name')}
          title={t('app.team.hou.title')}
        />
        <TeamMember
          description={t('app.team.evgeniy.description')}
          imageUrl="evgeniy.png"
          name={t('app.team.evgeniy.name')}
          title={t('app.team.evgeniy.title')}
        />
        <TeamMember
          description={t('app.team.darmen.description')}
          imageUrl="darmen.png"
          name={t('app.team.darmen.name')}
          title={t('app.team.darmen.title')}
        />
        <TeamMember
          description={t('app.team.askhat.description')}
          imageUrl="askhat.png"
          name={t('app.team.askhat.name')}
          title={t('app.team.askhat.title')}
        />
        <TeamMember
          description={t('app.team.kuanysh.description')}
          imageUrl="kuanysh.png"
          name={t('app.team.kuanysh.name')}
          title={t('app.team.kuanysh.title')}
        />
      </div>
    </div>
  );
};
