import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyFive: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.5.title')}
      </h2>
      <p className="font-black">{t('privacy.5.1')}</p>
      <p>{t('privacy.5.2')}</p>
      <p>
        <a href="mailto:support@chesslegends.ai">support@chesslegends.ai</a>
      </p>
      <p className="font-black">{t('privacy.5.3')}</p>
      <p>{t('privacy.5.4')}</p>
      <p>
        <a href="mailto:support@chesslegends.ai">support@chesslegends.ai</a>
      </p>
      <p className="font-black">{t('privacy.5.5')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.5.6" />
      </p>
      <p>{t('privacy.5.7')}</p>
      <p>{t('privacy.5.8')}</p>
    </div>
  );
};
