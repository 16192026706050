import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const TermFive: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.5.title')}
      </h2>
      <p>{t('terms.5.1')}</p>
      <p>{t('terms.5.11')}</p>
      <p>{t('terms.5.2')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="terms.5.21" />
      </p>
      <p>{t('terms.5.3')}</p>
      <p>{t('terms.5.4')}</p>
    </div>
  );
};
