import LanguageRoute from '@components/atoms/LanguageRoute';
import { Route, Routes } from 'react-router-dom';

const LanguageRouter = ({ children }: { children: JSX.Element }) => {
  return (
    <Routes>
      <Route element={<LanguageRoute>{children}</LanguageRoute>} path="*" />
      <Route element={<LanguageRoute language="en">{children}</LanguageRoute>} path="en/*" />
      <Route element={<LanguageRoute language="ru">{children}</LanguageRoute>} path="ru/*" />
    </Routes>
  );
};

export default LanguageRouter;
