import { LocaleLink } from '@components/atoms/LocaleLink';
import { PageMeta } from '@context/PageMetaContext';
import { Button, Result, ResultProps } from 'antd';
import { FC } from 'react';

const NotFoundPage: FC<ResultProps> = ({
  // extra = '',
  subTitle = 'Страница не найдена :(',
  title = '404',
}) => {
  return (
    <>
      <PageMeta title="404" />
      <Result
        extra={
          <LocaleLink to="/">
            <Button
              className="border-0 font-semibold px-8 lg:px-12 bg-ant-yellow-4 shadow-yellow text-black"
              htmlType="submit"
              shape="round"
              size="large"
              type="primary"
            >
              На главную
            </Button>
          </LocaleLink>
        }
        icon={
          <img
            alt="notFound"
            className="max-h-64 md:max-h-96 inline-flex self-center"
            src="/images/notfound.png"
          />
        }
        // status="404"
        subTitle={<h2 className="text-white">{subTitle}</h2>}
        title={<h1 className="text-white">{title}</h1>}
      />
    </>
  );
};
export default NotFoundPage;
