import { AppLayout } from '@components/layouts/AppLayout';
import LanguageRouter from '@components/molecules/LanguageRouter';
import { LandingPage } from '@components/pages/LandingPage';
import NotFoundPage from '@components/pages/NotFoundPage';
import { PrivacyPolicyPage } from '@components/pages/PrivacyPolicyPage';
import { TermsAndConditionsPage } from '@components/pages/TermsAndConditionsPage';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector('header')?.scrollIntoView({ behavior: 'smooth' });
  }, [pathname]);

  return (
    <LanguageRouter>
      <Routes>
        <Route element={<AppLayout menu miniHeader />}>
          <Route element={<LandingPage />} index />
        </Route>

        <Route element={<AppLayout />}>
          <Route element={<TermsAndConditionsPage />} path="terms" />
          <Route element={<PrivacyPolicyPage />} path="privacy" />
          <Route element={<NotFoundPage />} path="*" />
        </Route>
      </Routes>
    </LanguageRouter>
  );
};
export default App;
