import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyTwo: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.2.title')}
      </h2>
      <p className="font-black">{t('privacy.2.1')}</p>
      <p>{t('privacy.2.2')}</p>
      <div className="pl-4 lg:pl-8">
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.21" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.22" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.23" />
        </p>
      </div>
      <p>{t('privacy.2.3')}</p>
      <p>{t('privacy.2.4')}</p>
      <p>{t('privacy.2.5')}</p>
      <p className="font-black">{t('privacy.2.6')}</p>
      <p>{t('privacy.2.7')}</p>
      <div className="pl-4 lg:pl-8">
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.71" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.72" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.73" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.74" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.75" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.76" />
        </p>
        <p>
          <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.2.77" />
        </p>
      </div>
    </div>
  );
};
