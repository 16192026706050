import { LocaleLink } from '@components/atoms/LocaleLink';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const TermOne: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.1.title')}
      </h2>
      <p>{t('terms.1.1')}</p>
      <p>
        <Trans components={{ privacy: <LocaleLink to="/privacy" /> }} i18nKey="terms.1.2" />
      </p>
      <p>{t('terms.1.3')}</p>
      <p>{t('terms.1.4')}</p>
    </div>
  );
};
