import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export type BreadcrumbItemType = {
  link?: string;
  title: string;
};

export type PageMetaType = {
  breadcrumbs?: BreadcrumbItemType[];
  openMenuKeys?: string[];
  selectedMenuKeys?: string[];
  subTitle?: string;
  title?: string;
};

export type PageMetaContextType = PageMetaType & {
  setBreadcrumbs: (breadcrumbs?: BreadcrumbItemType[]) => void;
  setOpenMenuKeys: (keys?: string[]) => void;
  setSelectedMenuKeys: (keys?: string[]) => void;
};

export const PageMetaContext = createContext<PageMetaContextType>({
  breadcrumbs: [],
  openMenuKeys: [],
  selectedMenuKeys: [],

  setBreadcrumbs: () => {
    //
  },
  setOpenMenuKeys: () => {
    //
  },
  setSelectedMenuKeys: () => {
    //
  },
});

export const PageMetaContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[] | undefined>([]);
  const [openMenuKeys, setOpenMenuKeys] = useState<string[] | undefined>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItemType[] | undefined>([]);

  return (
    <PageMetaContext.Provider
      value={{
        breadcrumbs,
        openMenuKeys,
        selectedMenuKeys,
        setBreadcrumbs,
        setOpenMenuKeys,
        setSelectedMenuKeys,
      }}
    >
      {children}
    </PageMetaContext.Provider>
  );
};

export const PageMeta: FC<PageMetaType> = ({
  breadcrumbs,
  openMenuKeys,
  selectedMenuKeys,
  subTitle,
  title,
}) => {
  const { t } = useTranslation();
  const pageMetaContext = useContext(PageMetaContext);

  useEffect(() => {
    pageMetaContext.setOpenMenuKeys(openMenuKeys);
  }, [openMenuKeys]);

  useEffect(() => {
    pageMetaContext.setSelectedMenuKeys(selectedMenuKeys);
  }, [selectedMenuKeys]);

  useEffect(() => {
    pageMetaContext.setBreadcrumbs(breadcrumbs);
  }, [breadcrumbs]);

  const metaTitle = `${
    title && subTitle ? `${subTitle} — ${title} — ` : title ? `${title} — ` : ''
  } Chess Legends`;
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta content={metaTitle} name="og:title" />
      <meta content={t('meta.description')} name="description" />
      <meta content={t('meta.description')} name="og:description" />
      <meta content={t('meta.keywords')} name="keywords" />
      <meta content={process.env.REACT_APP_URL} name="og:url" />
      <meta
        content={process.env.NODE_ENV !== 'production' ? 'noindex,nofollow' : 'index,follow'}
        name="robots"
      />
    </Helmet>
  );
};
