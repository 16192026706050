import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { ChangeLanguageButton } from '@components/atoms/ChangeLanguageButton';
import { LocaleLink } from '@components/atoms/LocaleLink';
import { Button, Drawer, Layout } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

export const Header: FC<{ menu?: boolean }> = ({ menu }) => {
  const { t } = useTranslation();

  const items: { anchor: string; title: string }[] = [
    { anchor: 'whoWeAre', title: t('app.whoWeAre.anchor') },
    { anchor: 'advantages', title: t('app.advantages.anchor') },
    { anchor: 'animation', title: t('app.3d.anchor') },
    { anchor: 'team', title: t('app.team.anchor') },
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout.Header className="flex flex-row justify-evenly bg-transparent">
      <LocaleLink className="max-w-[150px]" to="/">
        <img alt="logo" className="object-contain w-auto max-h-full" src="/images/logo.png" />
      </LocaleLink>

      {menu && (
        <div className="hidden lg:flex text-white gap-8 truncate ">
          {items.map((item) => (
            <HashLink
              className="whitespace-nowrap text-white truncate"
              key={item.title}
              to={`/#${item.anchor}`}
            >
              {item.title}
            </HashLink>
          ))}
          <ChangeLanguageButton />
        </div>
      )}

      <ChangeLanguageButton absolute menu={menu} />

      {menu && (
        <>
          <Button
            className={`absolute top-4 left-4 lg:hidden border-0 z-30`}
            ghost
            icon={<MenuOutlined />}
            onClick={() => setIsOpen(!isOpen)}
          />

          <Drawer
            bodyStyle={{ borderRadius: 10, padding: '1rem 1.5rem' }}
            className="chess-menu"
            closable={true}
            closeIcon={<CloseOutlined className="text-white" />}
            contentWrapperStyle={{
              background: '#434F82',
              border: 0,
              borderRadius: '15px 20px 20px 20px',
              left: '0.5rem',
              maxHeight: '260px',
              maxWidth: '225px',
              overflow: 'hidden',
              top: '0.5rem',
            }}
            drawerStyle={{ background: '#434F82' }}
            headerStyle={{ background: '#434F82', border: 0 }}
            maskClosable={true}
            onClose={() => {
              setIsOpen(false);
            }}
            placement="left"
            title={<span className="text-white">{t('app.menu')}</span>}
            visible={isOpen}
          >
            <div className="grid gap-4">
              {items.map((item) => (
                <a
                  className="whitespace-nowrap text-white truncate"
                  href={`#${item.anchor}`}
                  key={item.title}
                  onClick={() => setIsOpen(false)}
                >
                  {item.title}
                </a>
              ))}
            </div>
          </Drawer>
        </>
      )}
    </Layout.Header>
  );
};
