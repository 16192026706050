import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyNine: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.9.title')}
      </h2>
      <p className="font-black">{t('privacy.9.1')}</p>
      <p>{t('privacy.9.2')}</p>
      <p>{t('privacy.9.3')}</p>
      <p>{t('privacy.9.4')}</p>
      <p className="font-black">{t('privacy.9.5')}</p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.9.6" />
      </p>
      <p>
        {t('privacy.9.7')} <a href="mailto:support@chesslegends.ai">support@chesslegends.ai</a>
      </p>
    </div>
  );
};
