import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyOne: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.1.title')}
      </h2>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.1" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.2" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.3" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.4" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.5" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.6" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.7" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.8" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.9" />
      </p>
      <p>
        <Trans components={{ bold: <span className="font-black" /> }} i18nKey="privacy.1.10" />
      </p>
    </div>
  );
};
