import axios from 'axios';
import qs from 'qs';

axios.interceptors.request.use((config) => {
  config.baseURL = process.env.REACT_APP_API_URL;
  config.paramsSerializer = function (params) {
    return qs.stringify(params, { arrayFormat: 'comma' });
  };
  return config;
});

// axios.interceptors.response.use(
//   (response) => response,
//   (error: AxiosError) => {
//     if (error && error.response) {
//       if (error.response.status >= 500) {
//         message.error({
//           content: 'Серверная ошибка. Попробуйте перезагрузить страницу',
//           key: 'serverErrorMessage',
//         });
//       }
//
//       return Promise.reject(error);
//     }
//   },
// );
