import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TermEleven: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.11.title')}
      </h2>
      <p>{t('terms.11.1')}</p>
      <p>{t('terms.11.11')}</p>
      <p>{t('terms.11.12')}</p>
      <p>{t('terms.11.2')}</p>
      <p>{t('terms.11.3')}</p>
      <p>{t('terms.11.4')}</p>
      <p>{t('terms.11.5')}</p>
    </div>
  );
};
