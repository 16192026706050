import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacySix: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white text-center">
        {t('privacy.6.title')}
      </h2>
      <p>{t('privacy.6.1')}</p>
      <p>{t('privacy.6.2')}</p>
    </div>
  );
};
