import { Advantage } from '@components/molecules/Advantage';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AdvantagesTab: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 sm:px-12 md:px-20 lg:px-28 xl:px-36" id="advantages">
      <h1 className="h1-title text-center">{t('app.advantages.title')}</h1>
      <div className="grid sm:grid-cols-2 gap-6 sm:gap-8 md:gap-10 lg:gap-16">
        <Advantage full link="ai" text={t('app.advantages.ai')} />
        <Advantage full link="animation" text={t('app.advantages.animation')} />
        <Advantage full link="edutainment" text={t('app.advantages.edutainment')} />
        <Advantage full link="multi-language" text={t('app.advantages.multiLanguage')} />
        <Advantage full link="video-lectures" text={t('app.advantages.videoLectures')} />
        <Advantage full link="effective" text={t('app.advantages.effective')} />
        <Advantage full link="kaissa" text={t('app.advantages.kaissa')} />
      </div>
    </div>
  );
};
