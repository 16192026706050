import { FC, ReactNode } from 'react';

type AdvantageType = {
  full?: boolean;
  link?: string;
  text: ReactNode;
};
export const Advantage: FC<AdvantageType> = ({ full, link, text }) => (
  <div className="flex flex-row gap-x-4 items-center">
    {full ? (
      <img alt="whoWeAre" className="w-16 h-16" src={`/images/advantages/full/${link}.png`} />
    ) : (
      <span className="advantage-icon w-14 h-14 shrink-0 text-center grid items-end text-4xl">
        <img alt="whoWeAre" src={`/images/advantages/${link}.png`} />
      </span>
    )}

    {typeof text == 'string' ? <p className="mb-0">{text}</p> : text}
  </div>
);
