import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TermTwo: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold uppercase text-white">
        {t('terms.2.title')}
      </h2>
      <p>{t('terms.2.1')}</p>
      <p>{t('terms.2.2')}</p>
      <p>{t('terms.2.3')}</p>
      <p>{t('terms.2.4')}</p>
      <p>{t('terms.2.5')}</p>
    </div>
  );
};
